<template>
  <zmAppPage :show-footer="showFooter" :showHeaderLeft="true" :show-header-right="true" :title="currentUnit.fullName">
    <zm-icon icon-class="menu" class="fs28" slot="headerRight" @click="showMenu = true"></zm-icon>
    <swiper class="my-swiper" :options="swiperOption" ref="mySwiper" @slideChange="slideChange">
      <swiper-slide v-for="(item, index) in pageContentList" :key="index">
        <div class="img-container">
          <img
            class="img"
            :src="`${item.url || defaultSrc}?x-oss-process=image/resize,m_pad,w_750,m_lfit,color_b4ddff`"
            alt=""
          />
          <a
            href="javascript:;"
            class="mask"
            v-for="(_item, index) in item.pageContentList"
            :key="index"
            :style="_item.style"
            @click="handleItem(_item)"
          ></a>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <!-- <div class="swiper-button-prev" ref="prev" slot="button-prev"></div>
      <div class="swiper-button-next" ref="next" slot="button-next"></div> -->
    </swiper>
    <van-popup v-model="showMenu" position="left" :style="{ width: '70%', height: '100%', position: 'fixed' }" round>
      <unit @change="unitChange"></unit>
    </van-popup>
    <PlayTab
      slot="footer"
      @change-unit="changeUnit"
      :audio-arr="currentPageAudio"
      @to-word="toWord"
      :is-playing.sync="isAutoPlay"
      @speed="changeSpeed"
      @next="nextPlay"
      :currentSlide="currentSlide"
      :playNext="playNext"
    ></PlayTab>
  </zmAppPage>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import PlayTab from './components/PlayTab';
import { mapActions, mapGetters } from 'vuex';
import { mainColor } from '@/styles/varHelper/_var.scss';
import bookApi from '@/api/src/book';
import Unit from '@/pages/index/components/Unit';
import * as path from '@/router/routes/routePath';
export default {
  name: 'UnitPlay',
  components: { PlayTab, swiper, swiperSlide, Unit },
  data() {
    const _self = this;
    return {
      showMenu: false,
      title: '',
      list: [],
      playNext: false,
      isPlay: false,
      isAutoPlay: false,
      audio: null,
      sec: 0,
      secTemp: 0,
      mainColor,
      currentSlide: 0,
      isPrev: false,
      audioSpeed: 1,
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // },
        paginationClickable: true,
        on: {
          touchEnd: e => {
            this.touchEnd(e);
          }
        }
      },
      currentItem: {},
      componentName: 'Home'
    };
  },

  computed: {
    ...mapGetters(['unitIndex', 'pageContentListLength', 'unitList', 'unitListLength', 'pageContentList']),
    unitId() {
      return this.$route.query.unitId;
    },
    isFirst() {
      return this.unitIndex === 0;
    },
    size() {
      return window.innerWidth / 1080;
    },
    showFooter() {
      return true;
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    currentPageAudio() {
      let res = [];
      if (this.pageContentList && this.pageContentList.length) {
        res = this.pageContentList[this.currentSlide].pageContentList.map(item => item.audio);
      }
      return res;
    },
    ...mapGetters(['mainTab', 'currentUnit'])
  },
  watch: {
    '$route.query'() {
      this.swiper.slideTo(0);
    },
    unitIndex() {
      this.swiper.slideTo(0);
    },
    isAutoPlay(val) {
      if (val) {
        this.pauseAudio();
      }
    }
  },
  destroyed() {
    this.pauseAudio();
  },
  created() {
    // this.getUnitContent();
  },
  methods: {
    ...mapActions('app', ['setUnitIndex', 'setPageContent']),
    getSwiper() {
      return this.$refs.mySwiper.swiper;
    },
    unitChange(show) {
      this.showMenu = show;
    },
    async touchEnd(e) {
      console.log('touchEnd', e.target === this.$refs.next, this.swiper.realIndex);
      const TR = this.swiper.translate;
      const width = (this.pageContentListLength - 1) * window.innerWidth;
      const isBtnPre = e.target === this.$refs.prev;
      const isBtnNext = e.target === this.$refs.next;
      const currentIndex = this.swiper.realIndex;
      console.log('currentIndex', TR, currentIndex, width);
      if (TR < -width || (isBtnNext && currentIndex === this.pageContentListLength - 1)) {
        // 最后一单元
        if (this.unitListLength - 1 === this.unitIndex) {
          this.$toast('当前是最后一单元');
        } else {
          this.changeUnit(true);
        }
      }
      if (TR > 50 || (isBtnPre && currentIndex === 0)) {
        if (this.unitIndex === 0) {
          this.$toast('前面没有了');
        } else {
          this.changeUnit(false);
        }
      }
    },

    async changeUnit(type) {
      // type true 加 false 减
      const num = type ? 1 : -1;
      await this.setPageContent(this.unitIndex + num);
      this.setUnitIndex(this.unitIndex + num);
      // console.log('dsfdsfsdf');
      if (!type) {
        this.isPrev = true;
        setTimeout(() => {
          this.swiper.slideTo(this.pageContentListLength - 1);
          this.isPrev = false;
        }, 200);
      }
    },
    handleItem(item) {
      this.pauseAudio();
      this.isAutoPlay = false;
      this.sec = Math.ceil(item.trackLength / 1000) + '"';
      this.secTemp = Math.ceil(item.trackLength / 1000) + '"';
      this.currentItem = item;
      this.play(item);
      // const duration = +item.trackLength || this.audio.duration;
      // console.log(item,duration)
      //     this.$notify({
      //       type: 'primary',
      //       message: item.textCn,
      //       duration: duration + 1000
      //     });
    },
    cutSec(num) {
      this.sec = num + '"';
      num -= 1;
      let timer = null;
      if (num > -1) {
        let timer = setTimeout(() => {
          this.cutSec(num);
        }, 1000);
      } else {
        this.sec = this.secTemp;
        this.isPlay = false;
        clearTimeout(timer);
        timer = null;
      }
    },
    toWord() {
      this.$router.push({
        path: path.PATH_UNIT_WORD,
        query: {
          unitId: this.unitList[this.unitIndex].id
        }
      });
    },
    pauseAudio() {
      this.isPlay = false;
      this.$notify.clear();
      if (this.audio) {
        this.audio.pause();
        this.audio = null;
      }
    },
    changeSpeed(command) {
      if (this.audio) {
        this.audio.playbackRate = command;
      }
      this.audioSpeed = command;
    },
    play(item) {
      let vm = this;
      if (!this.isPlay) {
        this.audio = new Audio();
        let numArr = this.sec.split('"'),
          vm = this;
        this.audio.autoplay = true;
        this.isPlay = true;
        this.$nextTick(function() {
          vm.cutSec(+numArr[0]);
          this.audio.src = item.audio;
          this.audio.playbackRate = this.audioSpeed;
          this.audio.oncanplay = function() {
            const audioDuration = Math.ceil(vm.audio.duration * 1000);
            const duration = +item.trackLength || audioDuration;
            console.log('dsfsf', duration);
            vm.$notify({
              type: 'primary',
              message: item.textCn,
              duration: duration + 1000
            });
          };
        });
      }
    },
    nextPlay() {
      if (this.currentSlide < this.pageContentListLength - 1) {
        this.getSwiper().slideNext();
        this.playNext = !this.playNext;
      } else {
        this.$toast('自动播放完成!');
      }
    },
    slideChange(e) {
      this.isAutoPlay = false;
      if (this.isPrev) return;
      const swiper = this.getSwiper();
      this.currentSlide = swiper.realIndex;
    },
    async getUnitContent(type = 0) {
      // type=1 请求下一个单元 type=-1 请求上一个单元 0的时候请求当前
      console.log(`get${this.isFirst ? 'First' : ''}UnitContent`);
      const res = await bookApi[`get${this.isFirst ? 'First' : ''}UnitContent`]({
        unitId: this.unitList[this.unitIndex + type].id
      });
      this.list = res.map(_item => {
        const size = window.innerWidth / 750;
        const pageContentList =
          (_item.pageContentList &&
            _item.pageContentList.map(item => {
              return {
                ...item,
                style: {
                  top: +item.top * size - 10 + 'px',
                  left: +item.leftNum * size - 10 + 'px',
                  height: +item.height * size + 10 + 'px',
                  width: +item.width * size + 10 + 'px',
                  transform: `rotate(${+item.angle}deg)`
                }
              };
            })) ||
          [];
        return {
          ..._item,
          pageContentList
        };
      });
    }
  }
};
</script>
<style lang="scss">
.my-swiper {
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    pointer-events: auto;
  }
}
.img-container {
  position: relative;

  .img {
    width: 100%;
  }
  .mask {
    position: absolute;
    display: inline-block;
    // padding:5px;
    box-sizing: content-box;
    border-radius: 5px;
    // border: 1px solid red;
    &:active {
      border: 1px solid red;
    }
  }
}
.van-popup--top {
  top: 0.88rem;
}
</style>
